import React, { Component } from 'react';
import {
  Col, Row, Card,
} from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import 'antd/dist/antd.css';
import './Home.css';

class ActiveGathering extends Component {
  render() {
    const { t, activeGathering, gatherings } = this.props;
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card style={{display: "flex", justifyContent: "center"}}>
            <div style={{ fontSize: '2em', color: '#08c', textAlign: "center", whiteSpace: "nowrap" }}>
                {
                `${moment(activeGathering.startDateTime).format("MMM.DD (ddd)")}`
                }
            </div>
            <div style={{ fontSize: '2em', color: '#08c', textAlign: "center", whiteSpace: "nowrap" }}>
                {
                `${moment(activeGathering.startDateTime).format("h:mmA")}`
                }
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Card style={{display: "flex", justifyContent: "center"}}>
            <div style={{ fontSize: '2em', color: '#08c', textAlign: "center" }}>
                {
                `${activeGathering.name ? t(gatherings[activeGathering.name]) : ""}`
                }
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(ActiveGathering);