import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Select, message, Input, InputNumber, DatePicker,
  Radio, Table,
} from 'antd';
import { withTranslation } from 'react-i18next';
import ReactGA from 'react-ga';

import moment from 'moment';
import _ from 'lodash';
import querystring from 'querystring';
import * as qs from 'query-string';

import amsAPI from '../../apis/amsAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class ClientDivisionReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptNumber: '',
      mode: 'new',
      gatheringTypes: [],
      members: [],
      localeChurches: [],
      churchDivisions: [],
      churchDistricts: [],
      countries: [],
      history: [],
      submitting: false,
    };

    this.columns = [
      {
        title: 'Changed By',
        dataIndex: 'changedBy',
        key: 'changedBy',
        width: 150,
        render: (text, record) => (
          <span>
            {record.changedBy ? record.changedBy.name : ""}
          </span>
        ),
      },
      {
        title: 'Changed On',
        dataIndex: 'changedAt',
        key: 'changedAt',
        width: 120,
        render: (text, record) => (
        <span>
          {record.changedAt ? moment(record.changedAt).format("YYYY-MM-DD") : null}
        </span>
        )
      },
      {
        title: 'Content',
        dataIndex: 'comment',
        key: 'comment',
        render: comment => <span>{comment}</span>,
      },
    ];
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.callApi('/locale_churches')
      .then(res => {
        this.setState({ localeChurches: res.data })
      });
    this.callApi('/church_divisions')
      .then(res => {
        this.setState({ churchDivisions: res.data })
      });
    this.callApi('/church_districts')
      .then(res => {
        this.setState({ churchDistricts: res.data })
      });
    this.callApi('/countries')
      .then(res => {
        this.setState({ countries: res.data })
      });
  }

  getReport = () => {
    const { receiptNumber } = this.state;
    const query = { receiptNumber };
    const url = `/ams/attendance_reports?${qs.stringify(query)}`
    this.callApi(url)
      .then(res => {
        const record = res.data && res.data[0];
        this.callApi(`/ams/members/${record.reportedBy}`)
          .then(res => {
            this.setState({ members: [res.member] });
            this.props.updateAttendanceReport({ ...record });
          });
        const historyUrl = `/ams/attendance_reports/${record._id}/history`
        this.callApi(historyUrl)
          .then(res => {
            const history = res.data;
            this.setState({ history });
          })
      })
    }

  callApi = async (url) => {
    const response = await amsAPI.getUrl(url)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmitAttendance = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit attendance report'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const { t, attendanceReport } = this.props;

    amsAPI.fetchUrl(`/ams/attendance_reports`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...attendanceReport}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        console.log('response', response)
        if (!_.isEmpty(response)) {
          message.success('Attendance successfully submitted.');
          const { receiptNumber } = response.data;
          this.props.setReceiptNumber(receiptNumber);
          this.setState({ submitting: false });
          this.props.history.push(`/division_attendance_receipt`);
        }
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting attendance.'));
    });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = value => {
    this.props.updateAttendanceReport({ reportedBy: value })
  };

  handleChurchDivisionSelect = async (value) => {
    this.props.updateAttendanceReport({ churchDivision: value });
    this.callApi(`/ams/church_districts?churchDivision=${value}`)
      .then(res => {
        this.setState({ churchDistricts: res.data })
      })
  };

  render() {
    const {
      members, localeChurches, churchDivisions, churchDistricts, countries,
      receiptNumber, mode, history, gatheringTypes,
    } = this.state;
    const { t, attendanceReport } = this.props;
    const liveOrReplay = Object.entries(constants.liveOrReplay);

    const gatheringEntries = [];
    gatheringTypes.forEach(item => {
      gatheringEntries.push([item.code, item.name]);
    });

    let modResult = [];
    let i = 0;
    history.forEach(item => {
      i++;
      modResult.push({ ...item, key: item._id, rowKey: { _id: item._id, rowNum: i } });
    });

    const disableSubmit = mode === "update" && !receiptNumber;
    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <h3>{t("AOD MED Online Attendance Report")}</h3>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={16}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label="Category">
                  <Radio.Group
                    onChange={e => {
                      const { value } = e.target;
                      this.setState({ mode: value, receiptNumber: null });
                      this.props.clearState();
                    }}
                    defaultValue={this.state.mode}
                  >
                    <Radio value={"new"}>New</Radio>
                    <Radio value={"update"}>Update</Radio>
                  </Radio.Group>
                </Form.Item>
                {this.state.mode === "update" &&
                  <div>
                    <Form.Item label="Receipt Number">
                      <Input
                        onChange={e => this.setState({ receiptNumber: e.target.value })}
                      />
                      <Button
                        onClick={this.getReport}
                        disabled={!this.state.receiptNumber}
                      >Retrieve</Button>
                    </Form.Item>
                  </div>
                }
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Form layout="horizontal" {...formItemLayout} >
                <Form.Item label={t("Reported By")}>
                  <Select
                    showSearch
                    placeholder={t("Input member name or id")}
                    dropdownMatchSelectWidth={false}
                    mode={this.state.mode}
                    optionFilterProp="value"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    value={attendanceReport.reportedBy}
                  >
                    {members.map(item => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {`${item.churchId} ${item.name}`}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Gathering Date:">
                  <DatePicker
                    onChange={(value) => this.props.updateAttendanceReport({ gatheringDate: value })}
                    value={attendanceReport.gatheringDate ? moment(attendanceReport.gatheringDate) : null}
                  />
                </Form.Item>
                <Form.Item label="Gathering:">
                  <Select
                    showSearch
                    placeholder="Select a gathering"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.props.updateAttendanceReport({ gatheringName: value })}
                    value={attendanceReport.gatheringName}
                  >
                    {gatheringEntries.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Type:">
                  <Select
                    showSearch
                    placeholder="Select a gathering type"
                    dropdownMatchSelectWidth={false}
                    style={{ width: 240 }}
                    onChange={value => this.props.updateAttendanceReport({ gatheringType: value })}
                    value={attendanceReport.gatheringType}
                  >
                    {liveOrReplay.map(([id, name]) =>
                      <Option key={id} value={id}>{name}</Option>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item label="Locale:">
                  <Select
                    showSearch
                    placeholder="Select a locale"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.updateAttendanceReport({ localeChurch: value })}
                    value={attendanceReport.localeChurch ? attendanceReport.localeChurch : ""}
                  >
                    {localeChurches.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Division:">
                  <Select
                    placeholder="Select a division"
                    dropdownMatchSelectWidth={false}
                    onChange={this.handleChurchDivisionSelect}
                    value={attendanceReport.churchDivision ? attendanceReport.churchDivision : ""}
                  >
                    {churchDivisions.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="District:">
                  <Select
                    placeholder="Select a district"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.updateAttendanceReport({ churchDistrict: value })}
                    disabled={churchDistricts.length === 0}
                    value={attendanceReport.churchDistrict ? attendanceReport.churchDistrict : ""}
                  >
                    {churchDistricts.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Country:">
                  <Select
                    placeholder="Select a country"
                    dropdownMatchSelectWidth={false}
                    onChange={value => this.props.updateAttendanceReport({ country: value })}
                    disabled={countries.length === 0}
                    value={attendanceReport.country ? attendanceReport.country : ""}
                  >
                    {countries.map(item => {
                      return <Option key={item._id} value={item._id}>{item.name}</Option>
                    })}
                  </Select>
                </Form.Item>
                <Form.Item label="Locale Attendance:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ localeAttendance: value })}
                    value={attendanceReport.localeAttendance}
                  />
                </Form.Item>
                <Form.Item label="Hookup Attendance:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ hookupAttendance: value })}
                    value={attendanceReport.hookupAttendance}
                  />
                </Form.Item>
                <Form.Item label="Visiting Brethren:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ visitingBrethren: value })}
                    value={attendanceReport.visitingBrethren}
                  />
                </Form.Item>
                <Form.Item label="Overseas Brethren:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ overseasBrethren: value })}
                    value={attendanceReport.overseasBrethren}
                  />
                </Form.Item>
                <Form.Item label="Absentees:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ absentees: value })}
                    value={attendanceReport.absentees}
                  />
                </Form.Item>
                <Form.Item label="Guests:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ guests: value })}
                    value={attendanceReport.guests}
                  />
                </Form.Item>
                <Form.Item label="Number of Devices:">
                  <InputNumber
                    onChange={value => this.props.updateAttendanceReport({ numberOfDevices: value })}
                    value={attendanceReport.numberOfDevices}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={12}>
              <Button block type="primary"
                loading={this.state.submitting}
                onClick={this.handleSubmitAttendance}
                disabled={disableSubmit}
              >
                {t("Submit")}
              </Button>
            </Col>
          </Row>
          {history.length > 0 &&
            <Row type="flex" justify="center" style={{ marginTop: "10px" }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Table
                  columns={this.columns}
                  dataSource={modResult}
                  pagination={{hideOnSinglePage: true}}
                />
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withTranslation()(ClientDivisionReport);
