import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Col, Row, Table, Button, Form, Input, Tooltip, Icon } from 'antd';
import { stringify } from 'query-string';
import moment from 'moment';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import * as constants from '../../helpers/constants';
import emmetAPI from '../../emmetAPI';

import 'antd/dist/antd.css';
import './Attendance.css';

const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: name => <span>{name}</span>,
  },
];

class MinistryAttendanceReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  state = {
    members: [],
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    const memberIds = this.props.checkedMembers;
    this.getMembers(memberIds)
      .then(res => this.setState({ members: res.members, loadingMembers: false }))
      .catch(err => console.log(err));
  }

  getMembers = async (ids) => {
    const query = {
      filter: JSON.stringify({ id: ids }),
    };
    const response = await emmetAPI.getUrl(`/ams/members?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  returnHome = async (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  clickAccessLink = async (e) => {
    e.preventDefault();
    const { members } = this.state;
    const { activeActivity } = this.props;
    emmetAPI.fetchUrl(`/ams/activity/${activeActivity._id}/link_accesses`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        member: members[0]._id,
        activity: activeActivity._id,
        action: 'click link',
      }),
    })
    .then(async res => {
      window.location.href = activeActivity.link;
    })
    .catch(err => {
      console.log('link update error');
    });
  };

  copyAccessLink = async () => {
    const { members } = this.state;
    const { activeActivity } = this.props;
    emmetAPI.fetchUrl(`/ams/activity/${activeActivity._id}/link_accesses`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        member: members[0]._id,
        activity: activeActivity._id,
        action: 'copy link',
      }),
    })
    .then(async res => {
      console.log('copied')
    })
    .catch(err => {
      console.log('link update error');
    });
  };

  render() {
    const { receiptNumber, activeActivity, gatheringReceiptNumber } = this.props;
    const { link } = activeActivity;
    const { members } = this.state;
    const formatedActivityDate = moment(activeActivity.startDateTime).format("MMM.DD(ddd), h:mmA");

    let modResult = [];
    let i = 0;
    members.forEach(item => {
      modResult.push({ ...item, key: ++i });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          {receiptNumber ?
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Your attendance has been successfully submitted.</h3>
                    <span>Your attendance receipt number is:</span>
                    <h2>{receiptNumber}</h2>
                  </div>
                </Col>
              </Row>
              {!_.isEmpty(gatheringReceiptNumber) &&
                <Row type="flex" justify="center">
                  <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                    <div>
                      <span>Your gathering receipt number is:</span>
                      <h2>{gatheringReceiptNumber}</h2>
                    </div>
                  </Col>
                </Row>
              }
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div>
                    <h3>These were the information submitted:</h3>
                  </div>
                  <div>
                    <h4>Activity Attendance:</h4>
                    <ul>
                      <li>Date:&nbsp;
                        <strong>{`${formatedActivityDate}`}</strong>
                      </li>
                      <li>Activity:&nbsp;
                        <strong>{constants.activities[activeActivity.name]}</strong>
                      </li>
                    </ul>
                    {!_.isEmpty(link) &&
                      <div>
                        <h4>Please access the activity through the following link:</h4>
                        <span><a href={link} onClick={this.clickAccessLink}>Open Link</a></span>
                        <Form.Item label="OR">
                          <Input
                            value={link}
                            disabled={true}
                          />
                          <CopyToClipboard text={link}>
                            <Tooltip title="Copied!" trigger="click">
                              <Button onClick={this.copyAccessLink}>
                                <Icon type="copy"/>Copy Link
                              </Button>
                            </Tooltip>
                          </CopyToClipboard>
                        </Form.Item>
                      </div>
                    }
                    <h4>Attendees:</h4>
                    <Table pagination={false} columns={columns} dataSource={modResult} />
                  </div>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                <div><Button onClick={this.returnHome}>Return to home</Button></div>
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(MinistryAttendanceReceipt);
