import {
  Card, Col, Form, Row,
} from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import emmetAPI from '../../emmetAPI';

import 'antd/dist/antd.css';
import './Home.css';

class LocaleSelection extends Component {
  state = {
    selectedLocale: undefined,
    localeChurches: [],
  };

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    let searchParams = new URLSearchParams({
      exclude: 'visiting',
    }).toString();
    this.callApi(`/ams/locale_churches?${searchParams}`)
      .then(res => {
        this.setState({ localeChurches: res.data })
      });
  }

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await emmetAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  handleLocaleSelect = (locale) => {
    console.log('locale', locale);
    this.props.clearGatherings();
    this.props.history.push(`/locale_selection/${locale._id}`);
  };

  render() {
    const { localeChurches } = this.state;

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Please Select the Locale Your Attending From</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Row type="flex" justify="center" style={{padding: '5px', margin: '20px'}}>
              {localeChurches.map(item => {
                return (
                  <button
                    style={{ border: "none", display: "flex", justifyContent: "center" }}
                    onClick={() => {
                      this.setState({ activeKey: "1" });
                      this.handleLocaleSelect(item);
                    }}
                    key={item._id}
                  >
                    <Card
                      style={{display: "flex", justifyContent: "center", width: 240, margin: '10px'}}
                    >
                      {item.name}
                    </Card>
                  </button>
                )
              })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const WrappedLocaleSelection = Form.create({ name: 'dynamic_rule' })(LocaleSelection);
export default withTranslation()(WrappedLocaleSelection);