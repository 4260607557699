export const activities = {
  'sto': 'STO Meeting',
  'gen': 'General Meeting',
  'grd': 'Graduation',
  'prc': 'Practice',
  'jrc': 'Japanese Recording',
  '247': '24x7 Destino',
  'ara': 'Aralan',
  'vmf': 'Virtual Music Festival',
};

export const liveOrReplay = {
  'replay': 'Replay',
  'live': 'Live',
};

export const channels = {
  'zoom': 'Zoom',
  'locale': `Locale (within ${process.env.REACT_APP_COUNTRY_NAME ? process.env.REACT_APP_COUNTRY_NAME : 'the country'})`,
  'locale-ext': `Locale (outside ${process.env.REACT_APP_COUNTRY_NAME ? process.env.REACT_APP_COUNTRY_NAME : 'the country'})`,
  'mcgi.tv': 'MCGI.tv',
  'line': 'Line',
  'youtube': 'YouTube',
  'facebook': 'Facebook',
  'instagram': 'Instagram',
  'others': 'Other'
};

export const gatheringWithDuties = [
  'nyr',
  'pmws',
  'ls',
  'mi01', 'mi02', 'mi03', 'mi04', 'mi05', 'mi06', 'mi07', 'mi08', 'mi09', 'mi10', 'mi11', 'mi12', 'mi13', 'mi14',
  'mcl1', 'mcl2', 'mcl3', 'mcl4', 'mcl5',
  'pm',
  'tg',
  'spbb1', 'spbb2', 'spbb3',
  'wbe',
  "wbs",
  'ws',
];

export const bloodTypes = [
  'A',
  'A+',
  'A-',
  'B',
  'B+',
  'B-',
  'AB',
  'AB+',
  "AB-",
  'O',
  'O+',
  'O-',
];

export const religions = [
  '33 AD Church of Christ',
  'Act of God',
  'Acts of Tabernacle',
  'Adamic Back to Christ',
  'Aglipayan',
  'Agnostic / Atheist',
  'Ako Yaong Ako Nga',
  'All For Jesus Ministry',
  'Alliance Christian Church',
  'Alliance Fundamental Church',
  'Amang SQOPQUZ',
  'Ana Baptist',
  'Anak at Espiritu Santo',
  'Anak ng Kaptid sa Loob ng Iglesia',
  'Ananda Marga Yoga Society',
  'Ang Verbo ng Dios',
  'Anglican',
  'Anti Instrument Church of Christ',
  'Assembly of God',
  'Association of Ifugao Bible Churches',
  'Back to Christ Ministry',
  'Bahai Faith',
  'Baptist',
  'Bathalang Buhay',
  'Bel, Melanesian Pidgin',
  'Bethesda Pasir Ris Mission Church',
  'Body of Christ Grace Mission',
  'Born Again',
  'Bread of Life',
  'Buddhism',
  'Bulacan Faith Fellowship',
  'Cathedral of Phraise',
  'Chaitarya Mission',
  'Channel of Blessing Ministry',
  'Charis Assembly of Christ, Inc.',
  'Childres of God',
  'Christ is the Answer',
  'Christ the Savior and Healer Fellowship',
  'Christian Alliance Church of the Philippnes',
  'Church of Back to Christ Royal Family',
  'Church of God Prophesy',
  'Church of the Foursquare Gospel in the Phils.',
  'El Shaddai',
  'Episcopal Church of the Phils.',
  'Evangelicals',
  'Freelance',
  'Gnotism',
  'Haligi at Suhay ng Katotohanan',
  'Hinduism',
  'Holy Trinity Church',
  'Iglesia ng Dios at ni Kristo sa Espiritu, Inc.',
  'Iglesia ng Dios kay Kristo Hesus, Juan Luna Tondo',
  'Iglesia ni Cristo ni Manalo',
  'Iglesiang Itinayo ni Jesukristo',
  'International Oneless Apostolic Churches of Jesus Christ, Inc.',
  'Islam',
  'Jainism',
  "Jehovah's Witness",
  'Jesus Christ the Name Above Every Time',
  'Jesus Is Lord Fellowship Incorporated (JIL)',
  'Jesus Miracle Crusade (JMC)',
  'Jesus Reign Forever International Ministry',
  'Judaism',
  'Kabanalbanalang Iglesia ng Dios kay Cristo Jesus',
  'Kingdom of Jesus Christ (QUIBOLOY)',
  'Las Salvation Army Apostolic Church',
  'Lutheran',
  'Metro Manila Bible Church',
  'Metro Manila Christian Church',
  'Moncadista',
  'Mormonism',
  'Pagadian City Alliance Church',
  'Paganism',
  'Pentecostal Missionary Church of Christ (4th Watch)',
  'Philadelphia Church of God',
  'Philippine Benevolent Missionaries Association',
  'Philippine Independent Church',
  'Presbyterian',
  'Protestantism',
  'Remnan Family of God in the  Far East Samal',
  'Resurrection Temple Peladelphia Church',
  'Rizalian',
  'Roman Catholic',
  'Saksi ni Jesucristo Iglesia ng Dios',
  'Seventh-Day Adventist Church',
  'Shintoism',
  'Sikhism',
  'Taoism',
  'Tertiary Sister of the Holy Family',
  'The United Methodist Church',
  'The Wesleyan Chruch',
  'United Back to Christ in the Philippines',
  'United Christian Church in the Philippines',
  'United Church of Christ in the Philippines',
  'United Pentecostal Church',
  'Universal Church of Christ Int.',
  'Victory Christian Fellowship',
  'Word of Hope',
  'Worldwide Church of God',
  'Zoroastrianism',
];

export const nationalities = [
  'Afghan',
  'Albanian',
  'Algerian',
  'American',
  'Andorran',
  'Angolan',
  'Anguillan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Bangladeshi',
  'Barbadian',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Beninese',
  'Bermudian',
  'Bhutanese',
  'Bolivian',
  'Botswanan',
  'Brazilian',
  'British',
  'British Virgin Islander',
  'Bruneian',
  'Bulgarian',
  'Burkinan',
  'Burmese',
  'Burundian',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Cape Verdean',
  'Cayman Islander',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Citizen of Antigua and Barbuda',
  'Citizen of Bosnia and Herzegovina',
  'Citizen of Guinea-Bissau',
  'Citizen of Kiribati',
  'Citizen of Seychelles',
  'Citizen of the Dominican Republic',
  'Citizen of Vanuatu ',
  'Colombian',
  'Comoran',
  'Congolese (Congo)',
  'Congolese (DRC)',
  'Cook Islander',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cymraes',
  'Cymro',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch',
  'East Timorese',
  'Ecuadorean',
  'Egyptian',
  'Emirati',
  'English',
  'Equatorial Guinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Faroese',
  'Fijian',
  'Filipino',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibraltarian',
  'Greek',
  'Greenlandic',
  'Grenadian',
  'Guamanian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hong Konger',
  'Hungarian',
  'Icelandic',
  'Indian',
  'Indonesian',
  'Iranian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakh',
  'Kenyan',
  'Kittitian',
  'Kosovan',
  'Kuwaiti',
  'Kyrgyz',
  'Lao',
  'Latvian',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtenstein citizen',
  'Lithuanian',
  'Luxembourger',
  'Macanese',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian',
  'Maltese',
  'Marshallese',
  'Martiniquais',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monegasque',
  'Mongolian',
  'Montenegrin',
  'Montserratian',
  'Moroccan',
  'Mosotho',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepalese',
  'New Zealander',
  'Nicaraguan',
  'Nigerian',
  'Nigerien',
  'Niuean',
  'North Korean',
  'Northern Irish',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean',
  'Paraguayan',
  'Peruvian',
  'Pitcairn Islander',
  'Polish',
  'Portuguese',
  'Prydeinig',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Salvadorean',
  'Sammarinese',
  'Samoan',
  'Sao Tomean',
  'Saudi Arabian',
  'Scottish',
  'Senegalese',
  'Serbian',
  'Sierra Leonean',
  'Singaporean',
  'Slovak',
  'Slovenian',
  'Solomon Islander',
  'Somali',
  'South African',
  'South Korean',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'St Helenian',
  'St Lucian',
  'Stateless',
  'Sudanese',
  'Surinamese',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Taiwanese',
  'Tajik',
  'Tanzanian',
  'Thai',
  'Togolese',
  'Tongan',
  'Trinidadian',
  'Tristanian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Turks and Caicos Islander',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'Uruguayan',
  'Uzbek',
  'Vatican citizen',
  'Venezuelan',
  'Vietnamese',
  'Vincentian',
  'Wallisian',
  'Welsh',
  'Yemeni',
  'Zambian',
  'Zimbabwean',
];