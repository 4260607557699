import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import {
  Button, Form, Select, Row, Col, PageHeader, Spin,
  message, Input, Switch, Card,
} from 'antd';
import ReactGA from 'react-ga';

import querystring from 'querystring';
import { stringify } from 'query-string';
import _ from 'lodash';

import amsAPI from '../../apis/amsAPI';

import 'antd/dist/antd.css';
import './CreateForm.css';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
    lg: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
    lg: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    const str = querystring.encode({
      q: value,
    });
    amsAPI.getUrl(`/ams/members?${str}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

class EditMemberAttendance extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props)
    this.state = {
      record: {},
      members: [],
      submitting: false,
      loadingRecord: true,
    }

    this.updateRecord = this.updateRecord.bind(this);
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingRecord: true });
    const { receiptNumber } = this.props.match.params;
    this.getRecord(receiptNumber)
      .then(res => {
        if (!_.isEmpty(res.data)) {
          let record = res.data[0];
          this.setState({ record, loadingRecord: false });
          this.props.setActiveGathering(record.gatheringId);
          this.props.setMembers(record.memberId._id);

          this.getMembers(record.memberId._id)
            .then(res => this.setState({ members: res.members, loadingMembers: false }))
            .catch(err => console.log(err));
        } else {
          this.setState({ record: {}, loadingRecord: false });
        }
      })
  }

  getRecord = async (receiptNumber) => {
    const response = await amsAPI.getUrl(`/ams/member_attendance?receiptNumber=${receiptNumber}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  updateRecord = async (updatedInfo) => {
    const currentInfo = this.state.record;
    this.setState({
      record: { ...currentInfo, ...updatedInfo }
    });
  };

  getMembers = async (ids) => {
    const query = {
      filter: JSON.stringify({ id: ids }),
    };
    const response = await amsAPI.getUrl(`/ams/members?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSearchMember = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleSubmit = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'update member attendance'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const { record } = this.state;
    const { t } = this.props;

    amsAPI.fetchUrl(`/ams/member_attendance/${record._id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...record}),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          const { receiptNumber, link, linkId, submissionDateTime, localeChurch } = response.data;
          this.props.setReceiptNumber(receiptNumber);
          this.props.setLinkInfo({ url: link, linkId, localeLinks: response.localeLinks });
          this.props.setAttendanceInfo({ receiptNumber, submissionDateTime, localeChurch });
          this.setState({ submitting: false });
          this.props.history.push(`/attendance_receipt`);
        } else if (response.error_id === 'ERR001') {
          this.setState({ submitting: false });
          message.error('Wrong pass key.');
        } else if (response.error_id === 'ERR002') {
          this.setState({ submitting: false });
          message.error('All gathering links have been allocated. Please contact your assigned worker.');
        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(t(response.error_id));
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false, mode: '' });
        this.props.clearState();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting attendance.'));
    });
  };

  rotate = () => {
    let newRotation = this.state.rotation + 90;
    if(newRotation === 360){
      newRotation = 0;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  render() {
    const { t } = this.props;
    const { receiptNumber } = this.props.match.params;
    const { record, members, loadingRecord } = this.state;
    const { memberId, isPerformingDuty, gatheringId, channel, otherChannel } = record;
    const { ministryId } = memberId || {};
    let channels = (gatheringId && gatheringId.channels) || [];
    const loading =  loadingRecord;
    if (loading) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    if (_.isEmpty(record._id)) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <div style={{ fontSize: '1.5em', color: '#08c', textAlign: "center", whiteSpace: "wrap" }}>
                    {t(`Sorry, but attendance with receipt number ${receiptNumber} not found.`)}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    return (
      <PageHeader>
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12}>
                <Form {...formItemLayout}>
                  <Form.Item label={t("Attendee")}>
                    <Select
                      showArrow={false}
                      placeholder={t("Input member name or id")}
                      onSearch={this.handleSearchMember}
                      onChange={value => {
                        this.updateRecord({ memberId: value });
                        this.props.setMembers(value);
                      }}
                      defaultValue={memberId && memberId._id}
                      disabled={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.churchId} ${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                  {(ministryId && ministryId.name.indexOf('Music Ministry') >= 0) ?
                    <Form.Item label={t("Are you on duty?")}>
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked) => {
                          this.updateRecord({ isPerformingDuty: checked })
                        }}
                        style={{ marginLeft: 5 }}
                        defaultChecked={ isPerformingDuty }
                      />
                    </Form.Item>
                    :
                    null
                  }
                  <Form.Item label={t("Attended through")}>
                    <Select
                      placeholder={t("Hook-up channel")}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        this.updateRecord({ channel: value, otherChannel: '' });
                        this.props.setChannel(value);
                        this.props.setOtherChannel('');
                      }}
                      value={channel}
                      defaultValue={channel}
                      allowClear={true}
                    >
                      {channels.map(name =>
                        <Option key={name} value={name}>{name.charAt(0).toUpperCase() + name.slice(1)}</Option>
                      )}
                    </Select>
                  </Form.Item>
                  {channel === "others" &&
                    <Form.Item label={t("Other Channel")}>
                      <Input
                        value={otherChannel}
                        onChange={e => {
                          this.updateRecord({ otherChannel: e.target.value });
                          this.props.setOtherChannel(e.target.value);
                        }}
                      />
                    </Form.Item>
                  }
                  <Form.Item {...tailFormItemLayout}>
                    <Button block type="primary"
                      loading={this.state.submitting}
                      onClick={this.handleSubmit}
                    >
                      {"Submit"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    );
  }
}

export default withTranslation()(withRouter(EditMemberAttendance));