import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Col, Row, Button } from 'antd';

import 'antd/dist/antd.css';
import './Redirect.css';

class DivisionAttendanceReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  state = {
    members: [],
  }

  goBack = async (e) => {
    e.preventDefault();
    this.props.clearState();
    this.props.history.push("/division");
  };

  render() {
    const { receiptNumber } = this.props;
    return (
      <div className="wrap">
        <div className="extraContent">
          {receiptNumber ?
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Division attendance report has been successfully submitted.</h3>
                    <span>Attendance receipt number is:</span>
                    <h2>{receiptNumber}</h2>
                  </div>
                  <div><Button onClick={this.goBack}>Back</Button></div>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                <div><Button onClick={this.goBack}>Return to Division Attendance Form</Button></div>
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(DivisionAttendanceReceipt);
