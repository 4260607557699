import { Button, Col, Form, Icon, Layout, Menu, message, Row, Select } from 'antd';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router";
import { NavLink, Route } from 'react-router-dom';

import ReactGA from 'react-ga';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { ClientFooter } from './common';
import { ClientDivisionReport, ClientHome, LocaleSelection } from './dashboard';
import { ClientAttendanceConfirm, ClientMemberSelect } from './form';
import { EditMemberAttendance } from './member_attendance';
import { MinistryAttendance, MinistryAttendanceReceipt } from './ministry_attendance';
import { AttendanceReceipt, DivisionAttendanceReceipt, Redirect } from './redirects';

import amsAPI from '../apis/amsAPI';

import 'antd/dist/antd.css';
import './Wrapper.css';

ReactGA.initialize('UA-145670123-1');

const { Content, Header, Sider } = Layout;
const { Option } = Select;
const WrappedClientMemberSelect = Form.create({ name: 'register' })(ClientMemberSelect);
const WrappedClientAttendanceConfirm = Form.create({ name: 'confirm' })(ClientAttendanceConfirm);
const WrappedEditMemberAttendance = Form.create({ name: 'member_attendance' })(EditMemberAttendance);

const BeamedNotesSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-music-note-beamed" viewBox="0 0 16 16">
    <path d="M6 13c0 1.105-1.12 2-2.5 2S1 14.105 1 13c0-1.104 1.12-2 2.5-2s2.5.896 2.5 2zm9-2c0 1.105-1.12 2-2.5 2s-2.5-.895-2.5-2 1.12-2 2.5-2 2.5.895 2.5 2z"/>
    <path fillRule="evenodd" d="M14 11V2h1v9h-1zM6 3v10H5V3h1z"/>
    <path d="M5 2.905a1 1 0 0 1 .9-.995l8-.8a1 1 0 0 1 1.1.995V3L5 4V2.905z"/>
  </svg>
);
const BeamedNotesIcon = props => <Icon component={BeamedNotesSvg} {...props} theme="twoTone" twoToneColor="#eb2f96" />;

const PersonCircleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
    <path fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
    <path fillRule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
  </svg>
);
const PersonCircleIcon = props => <Icon component={PersonCircleSvg} {...props} theme="twoTone" twoToneColor="#eb2f96" />;

const TheatreMasksSvg = () => (
  <svg width="4.2333mm" height="4.2333mm" version="1.1" viewBox="0 0 4.2333 4.2333" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-24.568 -12.817)">
      <g transform="matrix(.00082763 0 0 -.00082847 24.568 17.05)" fill="#fff">
      <path d="m2950 5099c-368-24-869-108-1137-190-115-36-215-131-269-259-17-40-19-80-24-393l-5-348-235-79c-265-88-480-175-715-290-226-110-369-190-423-237-86-74-142-201-142-318 0-54 235-958 306-1178 228-704 766-1302 1439-1600 138-61 446-173 530-193 110-25 305-16 410 20 169 57 262 131 453 359 208 249 297 379 408 600l64 128 65 34c83 43 371 246 490 345 497 413 822 994 927 1655 22 134 23 167 23 795v655l-36 76c-43 90-115 166-191 203-60 29-316 93-523 131-445 82-971 113-1415 84zm886-184c83-8 203-22 265-30 230-32 641-120 702-151 45-23 95-72 120-119 22-40 22-43 22-665 0-678-2-705-60-966-74-326-234-669-444-949-93-123-285-322-406-420-126-102-395-289-460-319-131-62-308-72-450-25-127 42-479 295-674 484-415 403-665 904-743 1490-10 74-13 260-13 715 0 684-4 641 69 718 45 49 90 69 228 101 357 85 605 121 1063 155 110 8 639-4 781-19zm-2297-1700c78-604 349-1149 783-1576l109-106-97-32c-161-52-273-69-656-101-201-16-371-30-378-30-6 0-22-11-35-25-30-29-33-83-7-113 9-11 222-113 473-226 500-225 537-237 652-217 81 14 175 61 229 114 24 23 87 104 141 180l99 138 31-21c119-80 281-130 419-130 48 0 88-3 88-8 0-15-118-211-182-303-84-120-312-393-374-448-60-52-185-116-261-131-34-7-101-10-163-8-100 5-115 8-305 77-234 85-499 212-640 306-288 194-519 425-710 710-82 123-222 404-270 544-45 132-315 1141-315 1178 0 95 53 174 156 234 291 170 649 329 1014 450l175 58 6-212c4-117 12-252 18-302zm1109-1847c31-23 59-43 61-45 7-4-186-267-221-300-18-18-50-39-71-48-45-19-139-21-182-5-47 18-549 243-558 250-4 4 79 14 185 23 294 26 444 57 623 131 44 19 86 34 93 35 7 0 39-18 70-41z"/>
      <path d="m2515 3826c-83-16-162-45-230-85-58-34-137-100-133-111 2-4 29-31 60-60l58-52 37 30c104 81 202 114 332 114 125 0 223-35 326-117l40-31 116 124-56 46c-66 54-177 110-258 130-87 22-212 27-292 12z"/>
      <path d="m3902 3829c-121-18-256-81-346-161-22-20-39-38-36-40 3-1 31-27 62-57l57-54 38 30c85 65 167 100 260 112 139 17 270-17 378-99l62-47 57 63 56 63-48 41c-56 49-185 114-264 134-78 20-200 26-276 15z"/>
      <path d="m2329 2700c-23-14-42-65-35-94 8-32 768-656 841-691 28-13 81-29 118-36 80-15 171-2 252 36 29 14 219 162 442 344 421 345 422 345 389 409-29 56-31 56-439-19-407-74-532-87-699-72-68 6-272 39-455 72-336 62-385 68-414 51zm527-246c389-72 543-72 938 1 76 14 140 24 142 22 6-6-457-379-504-405-61-35-157-36-219-4-33 17-435 336-503 398-19 18-15 18 146-12z"/>
      <path d="m1235 2446c-132-26-242-79-334-162-62-56-144-162-135-175 3-5 36-25 73-44l68-34 49 62c134 168 363 234 564 163 41-15 81-28 89-29s30 27 52 68l38 70-32 17c-115 62-301 89-432 64z"/>
      </g>
    </g>
  </svg>
);
const TheatreMasksIcon = props => <Icon component={TheatreMasksSvg} {...props} theme="twoTone" twoToneColor="#eb2f96" />;


class AmsClientWrapper extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
		super();
		this.state = {
      lang: '',
      selectedMembers: [],
      mode: '',
      channel: '',
      otherChannel: '',
      receiptNumber: '',
      gatheringReceiptNumber: '',
      candidateReceiptNumber: '',
      linkInfo: {},
      attendanceInfo: {
        guestAttendance: [
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
        ],
      },
      activeGathering: {},
      activeActivity: {},
      attendanceReport: {},
      activeGatherings: [],
      isAdmin: false,
      isSignedIn: false,
      isGoogleLogin: false,
    };
    this.setMembers = this.setMembers.bind(this);
    this.setMode = this.setMode.bind(this);
    this.setActiveGathering = this.setActiveGathering.bind(this);
    this.setActiveGatherings = this.setActiveGatherings.bind(this);
    this.setActiveActivity = this.setActiveActivity.bind(this);
    this.setReceiptNumber = this.setReceiptNumber.bind(this);
    this.setGatheringReceiptNumber = this.setGatheringReceiptNumber.bind(this);
    this.setCandidateReferenceNumber = this.setCandidateReferenceNumber.bind(this);
    this.clearState = this.clearState.bind(this);
    this.goBack = this.goBack.bind(this);
    this.changeLang = this.changeLang.bind(this);
    this.updateAttendanceReport = this.updateAttendanceReport.bind(this);
  }

  onLoginSuccess = info => {
    amsAPI.fetchUrl(`/ams/login`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        email: info.profileObj.email,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        message.success("Successfully logged in.");
        const body = await res.json();
        this.setState({
          isSignedIn: true,
          isGoogleLogin: true,
          userInfo: {
            localeChurchId: body.localeChurchId,
            memberId: body._id,
            roles: body.roles,
            name: body.name,
            isAdmin: body.isAdmin,
            userMemberId: body._id,
          },
          err: null
        })
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      message.error('Error logging in.');
    });
  }

  logoutUser = () => {
    this.setState({
      isSignedIn: false,
      isGoogleLogin: false,
    });
  }

  onLoginFailed = (err) => {
    console.log('login failed', err)
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  onLogoutSuccess = () => {
    this.setState({
      isSignedIn: false,
    })
  }

  onLogoutFailed = (err) => {
    this.setState({
      isSignedIn: false,
      error: err,
    })
  }

  setActiveGathering = (value) => {
    this.setState({ activeGathering: value });
  }

  setActiveGatherings = (value) => {
    this.setState({ activeGatherings: value });
  }

  clearGatherings = () => {
    this.setState({ activeGathering: undefined,  activeGatherings: []});
  }

  setActiveActivity= (value) => {
    this.setState({ activeActivity: value });
  }

  setMode = (value) => {
    this.setState({ mode: value });
  }

  setChannel = (value) => {
    this.setState({ channel: value });
  }

  setOtherChannel = (value) => {
    this.setState({ otherChannel: value });
  }

  setAttendendLocaleChurch = (value) => {
    this.setState({ attendedLocaleChurch: value });
  }

  setAttendendLocaleChurchExt = (value) => {
    this.setState({ attendedLocaleChurchExt: value });
  }

  setMembers = (value) => {
    if (typeof value === "string") {
      this.setState({ selectedMembers: [value] });
    } else {
      this.setState({ selectedMembers: value });
    }
  }

  setReceiptNumber = (value) => {
    this.setState({ receiptNumber: value });
  }

  setGatheringReceiptNumber = (value) => {
    this.setState({ gatheringReceiptNumber: value });
  }

  setCandidateReferenceNumber = (value) => {
    this.setState({ referenceNumber: value });
  }

  setAttendanceInfo = async (updatedInfo) => {
    const currentInfo = this.state.attendanceInfo;
    this.setState({
      attendanceInfo: { ...currentInfo, ...updatedInfo }
    });
  };

  setLinkInfo = (value) => {
    this.setState({ linkInfo: value });
  }

  clearState = () => {
    this.setState({
      attendanceReport: {},
      activeActivity: {},
      attendanceInfo: {
        guestAttendance: [
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
          { religion: "", count: 0 },
        ],
      },
      selectedMembers: [],
      channel: '',
      otherChannel: '',
      mode: '',
      hasGuests: false,
    });
  }

  goBack() {
    this.clearState();
    this.props.history.goBack();
  }

  changeLang = value => {
    this.setState(prevState => ({ lang: value }));
    this.props.i18n.changeLanguage(value);
  };

  updateAttendanceReport = async (updatedInfo) => {
    const currentInfo = this.state.attendanceReport;
    this.setState({
      attendanceReport: { ...currentInfo, ...updatedInfo }
    });
  };

  render() {
    const { isSignedIn, isGoogleLogin } = this.state;
    const pathname = this.props.location.pathname;
    const { t } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible
          collapsedWidth="0"
          defaultCollapsed={true}
        >
          <div className="logo" />
          <Menu
            theme="dark"
            mode="inline"
          >
            <Menu.Item key="0">
              <NavLink
                to={"/"}
              >
                <Icon type="home" />
                <span className="nav-text">Home</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink
                to={"/ministry_attendance/sto"}
              >
                <PersonCircleIcon />
                <span className="nav-text">STO</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="3">
              <NavLink
                to={"/ministry_attendance/music"}
              >
                <BeamedNotesIcon />
                <span className="nav-text">Music Ministry</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
              <NavLink
                to={"/ministry_attendance/teatro"}
              >
                <TheatreMasksIcon />
                <span className="nav-text">Teatro Kristiano</span>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              position: 'fixed',
              zIndex: 1,
              width: '100%',
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item
                key="2"
                style={{ padding: '0' }}
              >
                {!(pathname === "/" || pathname === "/redirect") &&
                  <Button type="default" size="large" onClick={this.goBack}>{t("Back")}</Button>
                }
              </Menu.Item>
            </Menu>
            <Menu
              selectable={false}
              theme="dark"
              mode="horizontal"
              style={{ lineHeight: '20px' }}
            >
              <Menu.Item key="1">
                <Select
                  onChange={this.changeLang}
                  defaultValue="en"
                  style={{ marginRight: "10px" }}
                >
                  <Option key="en" value="en">English</Option>
                  <Option key="jp" value="jp">日本語</Option>
                </Select>
                {(!isSignedIn && pathname !== "/") &&
                  <GoogleLogin
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={this.onLoginSuccess}
                    onFailure={this.onLoginFailed}
                    cookiePolicy={'single_host_origin'}
                  />
                }
                {(isSignedIn && isGoogleLogin) &&
                  <GoogleLogout
                    clientId={process.env.REACT_APP_CLIENT_ID}
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                    onFailure={this.onLogoutFailed}
                  />
                }
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ padding: '0 50px', marginTop: 75 }}>
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={pathname === "/" ? 12 : 24}>
                <div style={{ padding: 24, background: '#fff', minHeight: 320 }}>
                  <Route exact path="/"
                    render={(props) =>
                      <LocaleSelection
                        {...props}
                        clearGatherings={this.clearGatherings}
                      />
                    }
                  />
                  <Route exact path="/locale_selection"
                    render={(props) =>
                      <LocaleSelection
                        {...props}
                        clearGatherings={this.clearGatherings}
                      />
                    }
                  />
                  <Route exact path="/locale_selection/:localeChurchId"
                    render={(props) =>
                      <ClientHome
                        {...props}
                        activeGathering={this.state.activeGathering}
                        activeGatherings={this.state.activeGatherings}
                        checkedMembers={this.state.selectedMembers}
                        channel={this.state.channel}
                        otherChannel={this.state.otherChannel}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        attendanceInfo={this.state.attendanceInfo}
                        attendedLocaleChurch={this.state.attendedLocaleChurch}
                        attendedLocaleChurchExt={this.state.attendedLocaleChurchExt}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setChannel={this.setChannel}
                        setOtherChannel={this.setOtherChannel}
                        setAttendendLocaleChurch={this.setAttendendLocaleChurch}
                        setAttendendLocaleChurchExt={this.setAttendendLocaleChurchExt}
                        setActiveGathering={this.setActiveGathering}
                        setActiveGatherings={this.setActiveGatherings}
                        setReceiptNumber={this.setReceiptNumber}
                        setLinkInfo={this.setLinkInfo}
                        setAttendanceInfo={this.setAttendanceInfo}
                        clearState={this.clearState}
                      />
                    }
                  />
                  <Route exact path="/attendance/:localeChurchId/member_select"
                    render={(props) =>
                      <WrappedClientMemberSelect
                        {...props}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        checkedMembers={this.state.selectedMembers}
                        setActiveGathering={this.setActiveGathering}
                        activeGathering={this.state.activeGathering}
                        clearState={this.clearState}
                        mode={this.state.mode}
                      />
                    }
                  />
                  <Route exact path="/attendance/confirm_attendance"
                    render={(props) =>
                      <WrappedClientAttendanceConfirm {...props}
                        checkedMembers={this.state.selectedMembers}
                        channel={this.state.channel}
                        otherChannel={this.state.otherChannel}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />
                  <Route exact path="/attendance_receipt"
                    render={(props) =>
                      <AttendanceReceipt
                        receiptNumber={this.state.receiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        channel={this.state.channel}
                        otherChannel={this.state.otherChannel}
                        attendedLocaleChurch={this.state.attendedLocaleChurch}
                        attendedLocaleChurchExt={this.state.attendedLocaleChurchExt}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        linkInfo={this.state.linkInfo}
                        attendanceInfo={this.state.attendanceInfo}
                      />
                    }
                  />
                  <Route exact path="/attendance_receipt/:receiptNumber"
                    render={(props) =>
                      <WrappedEditMemberAttendance
                        {...props}
                        activeGathering={this.state.activeGathering}
                        activeGatherings={this.state.activeGatherings}
                        checkedMembers={this.state.selectedMembers}
                        channel={this.state.channel}
                        otherChannel={this.state.otherChannel}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        attendanceInfo={this.state.attendanceInfo}
                        attendedLocaleChurch={this.state.attendedLocaleChurch}
                        attendedLocaleChurchExt={this.state.attendedLocaleChurchExt}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setChannel={this.setChannel}
                        setOtherChannel={this.setOtherChannel}
                        setActiveGathering={this.setActiveGathering}
                        setActiveGatherings={this.setActiveGatherings}
                        setReceiptNumber={this.setReceiptNumber}
                        setLinkInfo={this.setLinkInfo}
                        setAttendanceInfo={this.setAttendanceInfo}
                        clearState={this.clearState}
                      />
                    }
                  />

                  <Route exact path="/division"
                    render={(props) =>
                      <ClientDivisionReport
                        {...props}
                        updateAttendanceReport={this.updateAttendanceReport}
                        setReceiptNumber={this.setReceiptNumber}
                        attendanceReport={this.state.attendanceReport}
                        receiptNumber={this.state.receiptNumber}
                        clearState={this.clearState}
                      />
                    }
                  />
                  <Route exact path="/division_attendance_receipt"
                    render={(props) =>
                      <DivisionAttendanceReceipt
                        receiptNumber={this.state.receiptNumber}
                        attendanceReport={this.state.attendanceReport}
                        clearState={this.clearState}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance/music"
                    render={(props) =>
                      <MinistryAttendance
                        {...props}
                        ministry="mm"
                        activeActivity={this.state.activeActivity}
                        checkedMembers={this.state.selectedMembers}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setActiveActivity={this.setActiveActivity}
                        setReceiptNumber={this.setReceiptNumber}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        setActiveGathering={this.setActiveGathering}
                        setGatheringReceiptNumber={this.setGatheringReceiptNumber}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance_receipt/music"
                    render={(props) =>
                      <MinistryAttendanceReceipt
                        ministry="mm"
                        receiptNumber={this.state.receiptNumber}
                        gatheringReceiptNumber={this.state.gatheringReceiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        clearState={this.clearState}
                        activeActivity={this.state.activeActivity}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance/teatro"
                    render={(props) =>
                      <MinistryAttendance
                        {...props}
                        ministry="tk"
                        activeActivity={this.state.activeActivity}
                        checkedMembers={this.state.selectedMembers}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setActiveActivity={this.setActiveActivity}
                        setReceiptNumber={this.setReceiptNumber}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        setActiveGathering={this.setActiveGathering}
                        setGatheringReceiptNumber={this.setGatheringReceiptNumber}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance_receipt/teatro"
                    render={(props) =>
                      <MinistryAttendanceReceipt
                        ministry="tk"
                        receiptNumber={this.state.receiptNumber}
                        gatheringReceiptNumber={this.state.gatheringReceiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        clearState={this.clearState}
                        activeActivity={this.state.activeActivity}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance/workers"
                    render={(props) =>
                      <MinistryAttendance
                        {...props}
                        ministry="sm"
                        activeActivity={this.state.activeActivity}
                        checkedMembers={this.state.selectedMembers}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setActiveActivity={this.setActiveActivity}
                        setReceiptNumber={this.setReceiptNumber}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        setActiveGathering={this.setActiveGathering}
                        setGatheringReceiptNumber={this.setGatheringReceiptNumber}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance_receipt/workers"
                    render={(props) =>
                      <MinistryAttendanceReceipt
                        ministry="sm"
                        receiptNumber={this.state.receiptNumber}
                        gatheringReceiptNumber={this.state.gatheringReceiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        clearState={this.clearState}
                        activeActivity={this.state.activeActivity}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance/sto"
                    render={(props) =>
                      <MinistryAttendance
                        {...props}
                        ministry="sto"
                        activeActivity={this.state.activeActivity}
                        checkedMembers={this.state.selectedMembers}
                        mode={this.state.mode}
                        receiptNumber={this.state.receiptNumber}
                        setMembers={this.setMembers}
                        setMode={this.setMode}
                        setActiveActivity={this.setActiveActivity}
                        setReceiptNumber={this.setReceiptNumber}
                        clearState={this.clearState}
                        activeGathering={this.state.activeGathering}
                        setActiveGathering={this.setActiveGathering}
                        setGatheringReceiptNumber={this.setGatheringReceiptNumber}
                      />
                    }
                  />

                  <Route exact path="/ministry_attendance_receipt/sto"
                    render={(props) =>
                      <MinistryAttendanceReceipt
                        ministry="sto"
                        receiptNumber={this.state.receiptNumber}
                        gatheringReceiptNumber={this.state.gatheringReceiptNumber}
                        checkedMembers={this.state.selectedMembers}
                        clearState={this.clearState}
                        activeActivity={this.state.activeActivity}
                        activeGathering={this.state.activeGathering}
                      />
                    }
                  />

                  <Route exact path="/redirect"
                    render={(props) =>
                      <Redirect />
                    }
                  />
                </div>
              </Col>
            </Row>
          </Content>
          <ClientFooter />
        </Layout>
      </Layout>
    );
  }
}

export default withTranslation()(withRouter(AmsClientWrapper));